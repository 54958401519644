<!DOCTYPE html>
<html lang="en-US" class="loading-site no-js">
<head>
	<meta charset="UTF-8" />
	<link rel="profile" href="http://gmpg.org/xfn/11" />
	<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet"
		integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
	
	<meta name="viewport" content="width=device-width, initial-scale=1" />
	<title> River docs</title>
 
	<style id="custom-css" type="text/css">
		:root {
			--primary-color: white ;
			--fs-color-primary: white ;
			--fs-color-secondary: #bb77ff;
			--fs-color-success: #00dadc;
			--fs-color-alert: #30ebb4;
			--fs-experimental-link-color: #bb77ff;
			--fs-experimental-link-color-hover: #c59cfb;
		}


		h1 {
		text-align: center;
		position: absolute;
		width: 100%;
		top: 50px;
		}

		.mobile-phone {
		margin: auto;
		margin-top: 170px;
		padding: 10px 10px 30px;
		width: 350px;
		height: 600px;
		box-shadow: 0 0 20px #e2e2e2;
		border-radius: 30px;
		}

		.screen {
		width: 100%;
		height: 100%;
		background: #f2f2f2;
		border-radius: 30px;
		overflow-y: auto;
		}

		.brove {
		width: 150px;
		height: 20px;
		background: white;
		position: absolute;
		margin: 0 100px;
		border-radius: 0 0 20px 20px;
		}

		.speaker {
		width: 60px;
		height: 5px;
		background: #d2d2d2;
		display: block;
		margin: auto;
		margin-top: 5px;
		border-radius: 20px;
		}
		#wrapper p:hover{
			font-weight: bold !important;
		}

	</style>
	<script>
	</script>
</head>
<body
	class="home page-template page-template-page-transparent-header page-template-page-transparent-header-php page page-id-10 lightbox nav-dropdown-has-arrow nav-dropdown-has-shadow nav-dropdown-has-border">
	<div id="wrapper">
		<nav class="navbar navbar-expand-lg bg-body-tertiary">
			<div class="container ">
				<a class="navbar-brand" href="#"><img
					src="https://i.ibb.co/1vr9G1h/logo1.jpg"
						class="img-fluid" style="width: 80px;" alt=""></a>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" style="margin-left: auto;"
					data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
					aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div class="collapse navbar-collapse  " id="navbarSupportedContent">
					<!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
						<li class="nav-item">
						  <a class="nav-link active" aria-current="page" href="#">Why River Doc?</a>
						</li>
						<li class="nav-item dropdown">
						  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							About
						  </a>
						  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
							<li><a class="dropdown-item" href="#">GET Know Revire DOC</a></li>
							<li><a class="dropdown-item" href="#">FAQS</a></li>
							<li><a class="dropdown-item" href="#">Our Story</a></li>
							<li><a class="dropdown-item" href="#">Our Mission & Values</a></li>
							<li><a class="dropdown-item" href="#">Testimonials</a></li>
							<li><a class="dropdown-item" href="#">Customer Success</a></li>
							<li><a class="dropdown-item" href="#">Customer Support</a></li>
							<li><a class="dropdown-item" href="#">Careers</a></li>
							<li><a class="dropdown-item" href="#">Contact</a></li>
						  </ul>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#">Pricing</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="#">Resources</a>
						</li>
					  </ul> -->


					  <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="list-style: none;">
						<li class="nav-item">
						  <a class="nav-link active" aria-current="page" href="#">Why River Doc?</a>
						</li>
						<li class="nav-item dropdown">
						  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							About
						  </a>
						  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
							<li><a class="dropdown-item" href="#">GET Know Revire DOC</a></li>
							<li><a class="dropdown-item" href="#">FAQS</a></li>
							<li><a class="dropdown-item" href="#">Our Story</a></li>
							<li><a class="dropdown-item" href="#">Our Mission & Values</a></li>
							<li><a class="dropdown-item" href="#">Testimonials</a></li>
							<li><a class="dropdown-item" href="#">Customer Success</a></li>
							<li><a class="dropdown-item" href="#">Customer Support</a></li>
							<li><a class="dropdown-item" href="#">Careers</a></li>
							<li><a class="dropdown-item" href="#">Contact</a></li>
						  </ul>
						</li>
						<li class="nav-item">
						  <a class="nav-link" href="#">Pricing</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="#">Resources</a>
						</li>
					  </ul>




					<a routerLink="/mainLogin" style="margin-left: auto;">
						<button type="button" class="btn btn-primary" style="background-color:#30ebb4;border: none;">Demo</button>
						<button type="button" class="btn btn-primary" style="background-color:#30ebb4;border: none;">Request Info</button>
						<button class="btn rounded-pill text-light fw-bold border-0 " style="background-color:#30ebb4 ;">
							LOGIN</button>
					</a>
					

				</div>
			</div>
		</nav>
		<main id="main" class="" style="background-image: url(../../assets/images/landingpage2.jpg);">
			<div id="content" role="main">
				<section class="section" id="section_1822345629">
					<div class="section-content relative">
						<div id="gap-1992462777" class="gap-element clearfix" style="display:block; height:auto;">
						
						</div>
					
							<div class="row align-middle align-center" id="About">
								<div class="col medium-10 small-12 large-6 medium-col-first">
									<div class="col-inner">
										<div id="text-1015643904" class="text">
											<h2 class="uppercase">River Docs to make care management easier</h2>
											<style>
												#text-1015643904 {
													font-size: 0.9rem;
												}
	
												@media (min-width:550px) {
													#text-1015643904 {
														font-size: 1rem;
													}
												}
	
												@media (min-width:850px) {
													#text-1015643904 {
														font-size: 1.1rem;
													}
												}
											</style>
										</div>
										<p><span style="font-weight: 400;">River Docs is preferred by administrators since it simplifies care for all. Your facility will use the most user-friendly app available to streamline operations with this software. Your staff can utilize River Docs if they have access to a smartphone! Your team will be ready to abandon your isolated paper-based system after you've reached a 95% acceptance rate.
											</span></p>
									</div>
									<style>
										#col-313945577>.col-inner {
											padding: 0px 0px 0px 0px;
										}
	
										@media (min-width:850px) {
											#col-313945577>.col-inner {
												padding: 0px 0px 0px 50px;
											}
										}
										p:hover{
											color: black;
										}
									</style>
								</div>
								<div class="col medium-10 small-12 large-6">
									<div class="col-inner">
										<div class="img has-hover x md-x lg-x y md-y lg-y" id="image_623760022">
											  <img src="../../assets/images/landingpage.jpeg" style="height: 560px;border-radius: 15px;" alt="">
											  
	
											<style>
												#image_623760022 {
													width: 100%;
												}
											</style>
										</div>
									</div>
								</div>
							</div>
						<div class="row align-middle align-center" id="About">
							<div class="col medium-10 small-12 large-6">
								<div class="col-inner">
									<div class="img has-hover x md-x lg-x y md-y lg-y" id="image_623760022">
										<div class='mobile-phone'>
											<div class='brove'><span class='speaker'></span></div>
											<div class='screen'>
										  <img src="../../assets/images/landingpage3.jpg" style="height: 560px;" alt="">
										  
											</div>
										  </div>
										  

										<style>
											#image_623760022 {
												width: 100%;
											}
										</style>
									</div>
								</div>
							</div>
							<div class="col medium-10 small-12 large-6 medium-col-first">
								<div class="col-inner">
									<div id="text-1015643904" class="text">
										<h2 class="uppercase">Your One-Stop Information and Documentation Resource: River Docs</h2>
										<style>
											#text-1015643904 {
												font-size: 0.9rem;
											}

											@media (min-width:550px) {
												#text-1015643904 {
													font-size: 1rem;
												}
											}

											@media (min-width:850px) {
												#text-1015643904 {
													font-size: 1.1rem;
												}
											}
										</style>
									</div>
									<p><span style="font-weight: 400;">For handling important data and paperwork, River Docs is your one-stop shop. All of your files are centralized, making it simple to access, distribute, and change them instantly. Teams can collaborate more effectively and keep everyone on the same page with its smooth collaboration features. The software provides editable templates to expedite documentation, and cloud-based security ensures that your data is secure and readily available from any location. Information management is made easy and hassle-free with River Docs, which streamlines the entire procedure.
										</span></p>
								</div>
								<style>
									#col-313945577>.col-inner {
										padding: 0px 0px 0px 0px;
									}

									@media (min-width:850px) {
										#col-313945577>.col-inner {
											padding: 0px 0px 0px 50px;
										}
									}
								</style>
							</div>
						</div>
						</div>
						<div id="gap-12681124" class="gap-element clearfix" style="display:block; height:auto;">
							<style>
								#gap-12681124 {
									padding-top: 185px;
								}
							</style>
						</div>
				</section>


				<section class="section" id="section_1115075300">
					<div class="section-content relative">	
						<div class="row align-center" id="Features">
							<div id="col-1263893425" class="col medium-10 small-12 large-6">
								<div class="col-inner text-center">
									<div id="text-2651350088" class="text">
										<h2 class="uppercase" style="color: white;font-weight: bold;">So, What Does<br />River docs <span
												style="font-weight:500">Actually Do</span>?</h2>
										<style>
											#text-2651350088 {
												font-size: 0.95rem;
											}

											@media (min-width:550px) {
												#text-2651350088 {
													font-size: 1.1rem;
												}
											}
										</style>
									</div>

									<p style="color: white;">The golden question! There’s too much to cover but here are the highlights. Make
										sure to schedule a demo to see these solutions in action.</p>

								</div>
							</div>
						</div>
						
						<div class="row align-equal align-center row-box-shadow-5" id="row-1769891138">
							<div id="col-262980609" class="col medium-9 small-12 large-4" data-animate="bounceInUp"
								data-animate-transform="true" data-animate-transition="true" data-animated="true">
								<div class="col-inner">
									<div class="icon-box featured-box icon-box-top text-left">
										<div class="icon-box-img" style="width: 70px">
											<div class="icon">
												<div class="icon-inner">
													<img loading="lazy" decoding="async" width="500" height="500"
														src="https://www.Alcomy.com/wp-content/uploads/2023/08/pills6.svg"
														class="attachment-medium size-medium" alt="">
												</div>
											</div>
										</div>
										<div class="icon-box-text last-reset">
											<h3 class="uppercase">Medication Management </h3>
											<p>We know that medication management can cause lots of headaches, which is
												why this part of River docs is a fan favorite. Make sure your MARs are
												recorded properly, order the right medication, leave specific notes for
												client care, and much much more with River docs.</p>
										</div>
									</div>
								</div>
							</div>
							<div id="col-373905521" class="col medium-9 small-12 large-4" data-animate="bounceInUp"
								data-animate-transform="true" data-animate-transition="true" data-animated="true">
								<div class="col-inner">
									<div class="icon-box featured-box icon-box-top text-left">
										<div class="icon-box-img" style="width: 70px">
											<div class="icon">
												<div class="icon-inner">
													<img loading="lazy" decoding="async" width="500" height="500"
														src="https://www.Alcomy.com/wp-content/uploads/2023/08/folders.svg"
														class="attachment-medium size-medium" alt="">
												</div>
											</div>
										</div>
										<div class="icon-box-text last-reset">
											<h3 class="uppercase">Document Management<br>
											</h3>
											<p>For admins tackling licenses or caregiving, River docs tool streamlines
												docs from pre-placement to admission. Caregiving teams love it too—we
												won’t leave them out. Send digital forms for signatures and instant
												access to licensing docs for sharing.</p>

										</div>
									</div>
								</div>
							</div>
							<div id="col-1340692209" class="col medium-9 small-12 large-4" data-animate="bounceInUp"
								data-animate-transform="true" data-animate-transition="true" data-animated="true">
								<div class="col-inner">
									<div class="icon-box featured-box icon-box-top text-left">
										<div class="icon-box-img" style="width: 70px">
											<div class="icon">
												<div class="icon-inner">
													<img loading="lazy" decoding="async" width="500" height="500"
														src="https://www.Alcomy.com/wp-content/uploads/2023/08/care.svg"
														class="attachment-medium size-medium" alt="">
												</div>
											</div>
										</div>
										<div class="icon-box-text last-reset">
											<h3 class="uppercase">Care Management<br>
											</h3>
											<p>Log up to 16 different activities of daily living and changes you notice
												in a client’s behavior or status, such as blood pressure, water intake,
												and more. For complicated care situations, staff members can leave
												detailed notes and reminders.</p>

										</div>
									</div>
								</div>
							</div>
							<div id="col-1533764406" class="col medium-9 small-12 large-4" data-animate="bounceInUp"
								data-animate-transform="true" data-animate-transition="true" data-animated="true">
								<div class="col-inner">
									<div class="icon-box featured-box icon-box-top text-left">
										<div class="icon-box-img" style="width: 70px">
											<div class="icon">
												<div class="icon-inner">
													<img loading="lazy" decoding="async" width="500" height="500"
														src="https://www.Alcomy.com/wp-content/uploads/2023/08/edit.svg"
														class="attachment-medium size-medium" alt="">
												</div>
											</div>
										</div>
										<div class="icon-box-text last-reset">


											<h3 class="uppercase">General Task Management<br>
											</h3>
											<p>Even though your day-to-day is focused on care, you still have a business
												to run. Set reminders to take out the trash, reconcile your books, or
												any other easily-forgotten task. Gone are the days of the scribbled
												sticky note. </p>

										</div>
									</div>
								</div>
							</div>
							<div id="col-722150917" class="col medium-9 small-12 large-4" data-animate="bounceInUp"
								data-animate-transform="true" data-animate-transition="true" data-animated="true">
								<div class="col-inner">
									<div class="icon-box featured-box icon-box-top text-left">
										<div class="icon-box-img" style="width: 70px">
											<div class="icon">
												<div class="icon-inner">
													<img loading="lazy" decoding="async" width="500" height="500"
														src="https://www.Alcomy.com/wp-content/uploads/2023/08/communication.svg"
														class="attachment-medium size-medium" alt="">
												</div>
											</div>
										</div>
										<div class="icon-box-text last-reset">
											<h3 class="uppercase">Communication<br>
											</h3>
											<p>With one place to post news and updates, you can make sure all staff get
												important announcements and acknowledge that they’ve read them (that
												second part is real important).</p>
										</div>
									</div>
								</div>
							</div>
							
						
							<style>
								#row-1769891138>.col>.col-inner {
									padding: 40px 40px 40px 40px;
									background-color: rgb(255, 255, 255);
									border-radius: 20px;
								}

								@media (min-width:550px) {
									#row-1769891138>.col>.col-inner {
										padding: 50px 50px 50px 50px;
									}
								}
							</style>
						</div>
							<div class="row align-middle align-center" id="row-1715599480">
								<div id="col-1708486415" class="col medium-10 small-12 large-6">
									<div class="col-inner">
										<div id="text-2393872986" class="text">
											<h2 class="uppercase">What Is River docs?</h2>
											<style>
												#text-2393872986 {
													font-size: 0.9rem;
												}

												@media (min-width:550px) {
													#text-2393872986 {
														font-size: 1.1rem;
													}
												}
											</style>
										</div>
										<p>You can use the River Docs app on your computer, tablet, or phone. It saves time and money for your facility and simplifies documentation and management. Doesn't anyone desire that?</p>
										<p>Care communities that make use of River Docs are less stressed and better organized. Your workers will find that using River Docs is as simple as sending a text or setting a calendar reminder thanks to an intuitive dashboard that includes a number of tools that make it easier for them to stay on top of their tasks, document, and make mistakes.</p>

									</div>
									<style>
										#col-1708486415>.col-inner {
											padding: 0px 0px 0px 0px;
										}

										@media (min-width:850px) {
											#col-1708486415>.col-inner {
												padding: 0px 50px 0px 0px;
											}
										}
									</style>
								</div>
								<div id="col-734435128" class="col medium-10 small-12 large-6" data-animate="bounceInRight"
									data-animate-transform="true" data-animate-transition="true" data-animated="true">
									<div class="col-inner">
										<div class="img has-hover x md-x lg-x y md-y lg-y" id="image_1382362464">
											<div class='mobile-phone'>
												<div class='brove'><span class='speaker'></span></div>
												<div class='screen'>
												<img src="../../assets/images/landingpage5.jpg" style="height: 560px;" alt="">
												</div>
											</div>
											

											<style>
												#image_1382362464 {
													width: 100%;
												}
											</style>
										</div>
									</div>
								</div>
							</div>
							<div id="gap-2145480814" class="gap-element clearfix" style="display:block; height:auto;">

								<style>
									#gap-2145480814 {
										padding-top: 100px;
									}
								</style>
						</div>
						<div class="row align-middle align-center" id="row-240489599">
							<div id="col-1056945014" class="col medium-10 small-12 large-6" data-animate="bounceInLeft"
								data-animate-transform="true" data-animate-transition="true" data-animated="true">
								<div class="col-inner">
									<div class="img has-hover x md-x lg-x y md-y lg-y" id="image_1863237320">
										<div class="img-inner dark">
											<img loading="lazy" decoding="async" width="1020" height="1020"
											src="../../assets/images/landingpage4.jpg"
												class="attachment-large size-large" style="border-radius: 15px;" alt="">
										</div>
										

										<style>
											#image_1863237320 {
												width: 100%;
											}
										</style>
									</div>
								</div>
							</div>
							<div id="col-1108125605" class="col medium-10 small-12 large-6 medium-col-first">
								<div class="col-inner">
									<div id="text-2251050808" class="text">


										<h2 class="uppercase"><span style="font-weight:500">Security &#038;
												Reliability</span> Are Our First Priorities<br />
										</h2>

										<style>
											#text-2251050808 {
												font-size: 0.9rem;
											}

											@media (min-width:550px) {
												#text-2251050808 {
													font-size: 1.1rem;
												}
											}
										</style>
									</div>

									<div class="accordion">

										<div id="accordion-630066101" class="accordion-item">
											<a id="accordion-630066101-label" class="accordion-title plain"
												href="#accordion-item-hipaa-compliant" aria-expanded="false"
												aria-controls="accordion-630066101-content">
												<button class="toggle" aria-label="Toggle"><i
														class="icon-angle-down"></i></button>
												<span>HIPAA Compliant</span>
											</a>
											<div id="accordion-630066101-content" class="accordion-inner"
												aria-labelledby="accordion-630066101-label">


												<p>River docs goes above and beyond the minimum HIPAA requirements when
													securing your data. We use the latest security best practices,
													including 256-bit military grade encryption, to ensure all your data
													is safe and protected.</p>

											</div>
										</div>

										<div id="accordion-1229884259" class="accordion-item">
											<a id="accordion-1229884259-label" class="accordion-title plain"
												href="#accordion-item-control-access-to-information-with-roles-and-permissions"
												aria-expanded="false" aria-controls="accordion-1229884259-content">
												<button class="toggle" aria-label="Toggle"><i
														class="icon-angle-down"></i></button>
												<span>Control Access to Information with Roles and Permissions</span>
											</a>
											<div id="accordion-1229884259-content" class="accordion-inner"
												aria-labelledby="accordion-1229884259-label">


												<p>Control who has access to specific information with customizable
													roles and permissions. You can even configure roles to only have
													access when they are physically at the facility.</p>

											</div>
										</div>

										<div id="accordion-3730663911" class="accordion-item">
											<a id="accordion-3730663911-label" class="accordion-title plain"
												href="#accordion-item-reliability-at-scale" aria-expanded="false"
												aria-controls="accordion-3730663911-content">
												<button class="toggle" aria-label="Toggle"><i
														class="icon-angle-down"></i></button>
												<span>Reliability At Scale</span>
											</a>
											<div id="accordion-3730663911-content" class="accordion-inner"
												aria-labelledby="accordion-3730663911-label">


												<p>As traffic increases during your busy hours, River docs scales with it to
													ensure performance isn&#8217;t compromised. We also backup and
													replicate your data regularly over multiple data centers. You know …
													in case that meteor ever strikes!</p>

											</div>
										</div>
										<div id="accordion-521978221" class="accordion-item">
											<a id="accordion-521978221-label" class="accordion-title plain"
												href="#accordion-item-automatic-updates" aria-expanded="false"
												aria-controls="accordion-521978221-content">
												<button class="toggle" aria-label="Toggle"><i
														class="icon-angle-down"></i></button>
												<span>Automatic Updates</span>
											</a>
											<div id="accordion-521978221-content" class="accordion-inner"
												aria-labelledby="accordion-521978221-label">
												<p>As we create new features to improve care, your software will update
													automatically. You&#8217;ll never have to worry about hidden fees or
													outdated features.</p>
											</div>
										</div>
										<div id="accordion-58539142" class="accordion-item">
											<a id="accordion-58539142-label" class="accordion-title plain"
												href="#accordion-item-your-business-in-the-cloud" aria-expanded="false"
												aria-controls="accordion-58539142-content">
												<button class="toggle" aria-label="Toggle"><i
														class="icon-angle-down"></i></button>
												<span>Your Business in the Cloud</span>
											</a>
											<div id="accordion-58539142-content" class="accordion-inner"
												aria-labelledby="accordion-58539142-label">
												<p>River docs gives you a rich digital experience to manage your
													organization without having to employ IT professionals or worry
													about the complexities of software development and maintaining
													servers.</p>
											</div>
										</div>
									</div>
								</div>
								<style>
									#col-1108125605>.col-inner {
										padding: 0px 0px 0px 0px;
									}

									@media (min-width:850px) {
										#col-1108125605>.col-inner {
											padding: 0px 0px 0px 50px;
										}
									}
								</style>
							</div>
						</div>
					</div>
					<style>
						#section_1115075300 {
							padding-top: 30px;
							padding-bottom: 30px;
						}

						#section_1115075300 .section-bg.bg-loaded {
							background-image: url(https://www.Alcomy.com/wp-content/uploads/2023/07/10-op-blue-grad-bg5.svg);
						}

						#section_1115075300 .section-bg {
							background-position: 92% 18%;
						}
					</style>
				</section>
				<div id="gap-1012837877" class="gap-element clearfix" style="display:block; height:auto;">

					<style>
						#gap-1012837877 {
							padding-top: 100px;
						}
					</style>
				</div>
				<section class="section" id="section_1546189707">
					<div class="section-content relative">
						<div class="row align-center" id="servers">
							<div class="col medium-11 small-12 large-11">
								<div class="col-inner">
									<div class="row align-middle align-center" id="row-336721147">
										<div id="col-757423217" class="col medium-10 small-12 large-6">
											<div class="col-inner">
												<div id="text-3969140277" class="text">
													<h2 class="uppercase" style="color: white;font-weight: bold;">Facilities We Serve<br />
													</h2>
													<style>
														#text-3969140277 {
															font-size: 0.9rem;
														}
														@media (min-width:550px) {
															#text-3969140277 {
																font-size: 1.1rem;
															}
														}
													</style>
												</div>
												<p style="color: white;">Our care management systems are commonly used by users here:</p>
												<ul>
													<li style="font-weight: 400;color: white;" aria-level="1"><span
															style="font-weight: 400;color: white;">Elderly Residential Care Facilities (RCFE)</span></li>
													<li style="font-weight: 400;color: white;" aria-level="1"><span
															style="font-weight: 400;color: white;">Residential Assisted Living
															(RAL)</span></li>
													<li style="font-weight: 400;color: white;" aria-level="1"><span
															style="font-weight: 400;color: white;">Assisted Living Facility
															(ALF)</span></li>
													<li style="font-weight: 400;color: white;" aria-level="1"><span
															style="font-weight: 400;color: white;">Adult Residential Facility
															(ARF)</span></li>
													<li style="font-weight: 400;color: white;" aria-level="1"><span
															style="font-weight: 400;color: white;">Home Care</span></li>
													<li style="font-weight: 400;color: white;" aria-level="1"><span
															style="font-weight: 400;color: white;">Day Programs</span></li>
												</ul>
											</div>
											<style>
												#col-757423217>.col-inner {
													padding: 0px 0px 0px 0px;
												}
												@media (min-width:850px) {
													#col-757423217>.col-inner {
														padding: 0px 50px 0px 0px;
													}
												}
											</style>
										</div>
										<div id="col-1877131565" class="col medium-10 small-12 large-6"
											data-animate="bounceInRight" data-animate-transform="true"
											data-animate-transition="true" data-animated="true">
											<div class="col-inner">
												<div class="img has-hover x md-x lg-x y md-y lg-y" id="image_429927975">
													<div class="img-inner dark">
														<img loading="lazy" decoding="async" width="1020" height="1020"
														src="../../assets/images/landingpage6.jpg"
															class="attachment-large size-large" style="border-radius: 15px;" alt="">
													</div>
													
													<style>
														#image_429927975 {
															width: 100%;
														}
													</style>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="gap-279495751" class="gap-element clearfix" style="display:block; height:auto;">

							<style>
								#gap-279495751 {
									padding-top: 100px;
								}
							</style>
						</div>
						<div class="row align-middle align-center" id="row-1953305553">
							<div id="col-1655716876" class="col medium-10 small-12 large-6">
								<div class="col-inner">
									<div id="text-2897808552" class="text">
										<h2 class="uppercase" style="color: white;font-weight: bold;">Software for Assisted Living Is Revolutionized by River Docs</h2>
										<style>
											#text-2897808552 {
												font-size: 0.9rem;
											}

											@media (min-width:550px) {
												#text-2897808552 {
													font-size: 1.1rem;
												}
											}
										</style>
									</div>
									<div id="text-2683871190" class="text">
										<h4>The Difference Between Assisted Living Software and River Docs:</h4>
										<style>
											#text-2683871190 {
												font-size: 0.95rem;
												color: white ;
											}

											#text-2683871190>* {
												color: white ;
											}
										</style>
									</div>
									<p style="color: white;">We are aware of the difficulties involved in facility administration, unlike many developers who perceive a chance for profit but have no prior experience in our sector. We developed River Docs to make facility management simpler and (almost) stress-free because we have been doing it for decades.</p>
									
								</div>
								<style>
									#col-1655716876>.col-inner {
										padding: 0px 50px 0px 0px;
									}
								</style>
							</div>
							<div id="col-1128041203" class="col medium-10 small-12 large-6">
								<div class="col-inner">
									<div class="row align-equal row-box-shadow-5" id="row-534618516">
										<div id="col-1140111505" class="col medium-6 small-12 large-6"
											data-animate="bounceInRight" data-animate-transform="true"
											data-animate-transition="true" data-animated="true">
											<div class="col-inner text-left">
												<div class="icon-box featured-box icon-box-top text-left">
													<div class="icon-box-img" style="width: 80px">
														<div class="icon">
															<div class="icon-inner">
																<img loading="lazy" decoding="async" width="500"
																	height="500"
																	src="https://www.Alcomy.com/wp-content/uploads/2023/08/mobile.svg"
																	class="attachment-medium size-medium" alt="">
															</div>
														</div>
													</div>
													<div class="icon-box-text last-reset">
														<div id="text-43801061" class="text">
															<p>Mobile-first experience</p>
															<style>
																#text-43801061 {
																	font-size: 1.3rem;
																}
															</style>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div id="col-1355819900" class="col medium-6 small-12 large-6"
											data-animate="bounceInRight" data-animate-transform="true"
											data-animate-transition="true" data-animated="true">
											<div class="col-inner text-left">
												<div class="icon-box featured-box icon-box-top text-left">
													<div class="icon-box-img" style="width: 80px">
														<div class="icon">
															<div class="icon-inner">
																<img loading="lazy" decoding="async" width="500"
																	height="500"
																	src="https://www.Alcomy.com/wp-content/uploads/2023/08/folder.svg"
																	class="attachment-medium size-medium" alt="">
															</div>
														</div>
													</div>
													<div class="icon-box-text last-reset">
														<div id="text-1645456347" class="text">
															<p>High user-adoption (95% to be exact)</p>
															<style>
																#text-1645456347 {
																	font-size: 1.3rem;
																}
															</style>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div id="col-1830735456" class="col medium-6 small-12 large-6"
											data-animate="bounceInRight" data-animate-transform="true"
											data-animate-transition="true" data-animated="true">
											<div class="col-inner text-left">
												<div class="icon-box featured-box icon-box-top text-left">
													<div class="icon-box-img" style="width: 80px">
														<div class="icon">
															<div class="icon-inner">
																<img loading="lazy" decoding="async" width="500"
																	height="500"
																	src="https://www.Alcomy.com/wp-content/uploads/2023/08/updates.svg"
																	class="attachment-medium size-medium" alt="">
															</div>
														</div>
													</div>
													<div class="icon-box-text last-reset">
														<div id="text-1506771980" class="text">
															<p>Frequent updates and features </p>
															<style>
																#text-1506771980 {
																	font-size: 1.3rem;
																}
															</style>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div id="col-1445475964" class="col medium-6 small-12 large-6"
											data-animate="bounceInRight" data-animate-transform="true"
											data-animate-transition="true" data-animated="true">
											<div class="col-inner text-left">
												<div class="icon-box featured-box icon-box-top text-left">
													<div class="icon-box-img" style="width: 80px">
														<div class="icon">
															<div class="icon-inner">
																<img loading="lazy" decoding="async" width="500"
																	height="500"
																	src="https://www.Alcomy.com/wp-content/uploads/2023/08/customer-service.svg"
																	class="attachment-medium size-medium" alt="">
															</div>
														</div>
													</div>
													<div class="icon-box-text last-reset">
														<div id="text-1524388735" class="text">
															<p>The best customer service, ever!</p>

															<style>
																#text-1524388735 {
																	font-size: 1.3rem;
																}
															</style>
														</div>
													</div>
												</div>
											</div>
										</div>
										<style>
											#row-534618516>.col>.col-inner {
												padding: 40px 40px 40px 40px;
												background-color: rgb(255, 255, 255);
												border-radius: 30px;
											}
										</style>
									</div>
								</div>
							</div>
						</div>
					</div>
					<style>
						#section_1546189707 {
							padding-top: 30px;
							padding-bottom: 30px;
						}

						#section_1546189707 .section-bg.bg-loaded {
							background-image: url(https://www.Alcomy.com/wp-content/uploads/2023/08/grad-bg2.svg);
						}

						#section_1546189707 .section-bg {
							background-position: 90% 13%;
						}
					</style>
				</section>
			</div>
		</main>
		<footer class="footer-wrapper">
			<div id="gap-374737480" class="gap-element clearfix" style="display:block; height:auto;">

				<style>
					#gap-374737480 {
						padding-top: 313px;
					}
				</style>
			</div>
			<div id="Contact">
				<section class="section" id="section_1669969892">
					<div class="bg section-bg fill bg-fill bg-loaded">
						<div class="section-bg-overlay absolute fill"></div>
					</div>
					<div class="ux-shape-divider ux-shape-divider--top ux-shape-divider--style-curve-2-invert">
						<svg viewBox="0 0 1000 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
							<path class="ux-shape-fill"
								d="M1000 100H0V4.71656C494.161 100.895 796.31 101.094 1000 5.31298V100Z"></path>
						</svg>
					</div>
					<div class="section-content relative">
						<div class="row align-center" id="row-1563992004">
							<div id="col-1099083077" class="col medium-11 small-12 large-11" data-animate="bounceInUp"
								data-animate-transform="true" data-animate-transition="true" data-animated="true">
								<div class="col-inner box-shadow-5" style="background-color:rgb(255,255,255);">
									<div class="row align-middle" id="row-1842148457">
										<div id="col-1860092462" class="col medium-12 small-12 large-6">
											<div class="col-inner">
												<div class="img has-hover x md-x lg-x y md-y lg-y" id="image_921660935">
													<div class="img-inner dark">
														<img width="1020" height="883"
														src="../../assets/images/landingpage1.jpg" 
															class="attachment-large size-large" style="border-radius: 15px;" alt="" decoding="async"
															loading="lazy">
													</div>
													
													<style>
														#image_921660935 {
															width: 100%;
														}
													</style>
												</div>
											</div>
										</div>
										<div id="col-1631930086" class="col medium-12 small-12 large-6">
											<div class="col-inner">
												<div id="text-4097877568" class="text">
													<h2 class="uppercase">Discover How River Docs Can Transform Your Approach to Caring</h2>

													<style>
														#text-4097877568 {
															font-size: 0.85rem;
														}

														@media (min-width:550px) {
															#text-4097877568 {
																font-size: 1.2rem;
															}
														}
													</style>
												</div>
												<p>Rely on River doctors and get rid of the paper and the associated stress. Everything you need to streamline care and overcome the difficulties in managing a care facility is included in our assisted living software. Set up a demo to see how River Docs can transform your company's operations!</p>
												
											</div>

											<style>
												#col-1631930086>.col-inner {
													padding: 0px 0px 0px 0px;
												}

												@media (min-width:850px) {
													#col-1631930086>.col-inner {
														padding: 0px 0px 0px 40px;
													}
												}
											</style>
										</div>



									</div>
								</div>
								<style>
									#col-1099083077>.col-inner {
										padding: 40px 40px 30px 40px;
										margin: -291px 0px 0px 0px;
										border-radius: 30px;
									}

									@media (min-width:550px) {
										#col-1099083077>.col-inner {
											padding: 50px 50px 40px 50px;
										}
									}
								</style>
							</div>
						</div>
						<div class="row row-collapse" id="row-1407005870">
							<div id="col-10638554" class="col small-12 large-12">
								<div class="col-inner text-left dark">
									<div class="is-divider divider clearfix"
										style="max-width:100%;height:1px;background-color:rgba(255, 255, 255, 0.349);">
									</div>
									<p><strong>River docs</strong> © 2024 All Rights reserved. Powered by <a href=""
											target="_blank" rel="noopener">Lemonade
											Stand</a> | <a href="#">Privacy Policy</a> | <a href="#">Terms and
											Conditions</a></p>
								</div>
							</div>
						</div>
					</div>
					<style>
						#section_1669969892 {
							padding-top: 80px;
							padding-bottom: 80px;
							background-color: white ;
						}

						#section_1669969892 .section-bg-overlay {
							background-color: rgba(0, 0, 0, 0.122);
						}

						#section_1669969892 .section-bg.bg-loaded {
							background-image: url(https://www.Alcomy.com/wp-content/uploads/2023/08/grad-bg.svg);
						}

						#section_1669969892 .ux-shape-divider--top svg {
							height: 118px;
							--divider-top-width: 100%;
						}
					</style>
				</section>
				<div class="absolute-footer dark medium-text-center small-text-center">
					<div class="container clearfix">
						<div class="footer-primary pull-left">
							<div class="copyright-footer">
								<strong>River docs</strong> © 2024 All Rights reserved. Powered by <a href="#"
									target="_blank">Lemonade Stand</a> | <a href="">Privacy Policy</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
	<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
		integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz"
		crossorigin="anonymous"></script>

</body>

</html>