
<div class="container-fluid ms-2">
         <h4>Health/Medical</h4>
</div>

<form [formGroup]="thisForm" (ngSubmit)="addMyForm()">
    
    <div class="container-fluid p-4">
        <div class="row p-4 border">          
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Patient Name</label>
                <select formControlName="patientName" class="form-select" id="">
                    <option value="">Select</option>
                    <option *ngFor="let resi of residentData" [value]="resi.first_name">{{ resi.first_name }} {{ resi.last_name }}</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Current Medical Conditions</label>
              <textarea formControlName="currentMedicalConditions" class="form-control" id=""></textarea>
            </div>         
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Medications</label>
              <textarea formControlName="medications" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Allergies</label>
                <textarea formControlName="allergies" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Recent Hospitalizations or Surgeries</label>
                <textarea formControlName="recentHospitalizationsAndSurgeries" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Primary Healthcare Provider</label>
                <input type="text" formControlName="primaryHealthCareProvider" class="form-control">
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Specialist Referrals</label>
                <textarea formControlName="specialistReferrals" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>  
        </div>
      
    </div>
</form>


<div class="container-fluid mt-5">
    
    <div class="row">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>Health Medical List</h4>
        </div>
        
    </div>
    
    <div class="row mt-4">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead style="background-color: #f6daf3; color: #3C3D37; white-space: nowrap;">
                        <tr>
                            <th>Patient Name</th>
                            <th>Current Medical Conditions</th>
                            <th>Medications</th>
                            <th>Allergies</th>
                            <th>Recent Hospitalizations And Surgeries</th>
                            <th>Primary Health Care Provider</th>
                            <th>Specialist Referrals</th>
                        </tr>
                    </thead>
                    <tbody style=" white-space: nowrap;">
                        <tr *ngFor="let data of healthMData" >
                            <td> {{ data.patientName }} </td>
                            <td>{{ data.currentMedicalConditions }}</td>
                            <td>{{ data.medications }}</td>
                            <td>{{ data.allergies }}</td>
                            <td>{{ data.recentHospitalizationsAndSurgeries }}</td>
                            <td>{{ data.primaryHealthCareProvider }}</td>
                            <td>{{ data.specialistReferrals }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        
    </div>
    
</div>