<div class="container-fluid">
    <div class="row">

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ms-2 ">
            <h4>Communication </h4>
        </div>

    </div>
</div>

<form [formGroup]="thisForm" (ngSubmit)="addMyForm()">

    <div class="container-fluid p-4">
        
        <div class="row p-4 border ">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Patient Name</label>
                <select formControlName="patientName" class="form-select" id="">
                    <option value="">Select</option>
                    <option *ngFor="let resi of residentData" [value]="resi.first_name">{{ resi.first_name }} {{ resi.last_name }}</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Primary Language</label>
                <select formControlName="primaryLanguage" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="English">English</option>
                    <option value="Hindi">Hindi</option>
                    <option value="Spanish">Spanish</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Communication Method</label>
                <select formControlName="communicationMethod" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Verbal">Verbal</option>
                    <option value="Non-verbal">Non-verbal</option>
                    <option value="Sign Language">Sign Language</option>
                    <option value="Writing">Writing</option>
                    <option value="Gestures">Gestures</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Hearing Ability</label>
                <select formControlName="haeringAbility" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Normal">Normal</option>
                    <option value="Hearing Loss">Hearing Loss</option>
                    <option value="Uses Hearing Aid">Uses Hearing Aid</option>
                    <option value="Deaf">Deaf</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Speech Clarity</label>
                <select formControlName="speechClarity" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Clear">Clear</option>
                    <option value="Impaired">Impaired</option>
                    <option value="Difficult to Understand">Difficult to Understand</option>
                    <option value="Non Verbal">Non Verbal</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Understanding of Instructions</label>
                <select formControlName="understandingOfInstrunction" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Fully Understands">Fully Understands</option>
                    <option value="Requires Simplification">Requires Simplification</option>
                    <option value="Needs Translation">Needs Translation</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Assistive Communication Devices</label>
                <div class="">
                    <div class="form-check me-3">
                        <input class="form-check-input" type="radio" formControlName="assistiveCommunicationDevices" id="exampleRadios1"
                            value="None" checked>
                        <label class="form-check-label" for="exampleRadios1">
                            None
                        </label>
                    </div>
                    <div class="form-check me-3">
                        <input class="form-check-input" type="radio" formControlName="assistiveCommunicationDevices" id="exampleRadios2"
                            value="Communication Board">
                        <label class="form-check-label" for="exampleRadios2">
                            Communication Board
                        </label>
                    </div>
                    <div class="form-check me-3">
                        <input class="form-check-input" type="radio" formControlName="assistiveCommunicationDevices" id="exampleRadios3"
                            value="Speech Generating Device">
                        <label class="form-check-label" for="exampleRadios3">
                            Speech Generating Device
                        </label>
                    </div>
                    <div class="form-check me-3">
                        <input class="form-check-input" type="radio" formControlName="assistiveCommunicationDevices" id="exampleRadios3"
                            value="Other">
                        <label class="form-check-label" for="exampleRadios3">
                            Other
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" class="btn btn-primary ">Submit</button>
            </div>
        </div>
    


    </div>

</form>


<div class="container-fluid mt-5">
    
    <div class="row">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>Communication List</h4>
        </div>
        
    </div>
    
    <div class="row mt-4">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead style="background-color: #f6daf3; color: #3C3D37;white-space: nowrap;">
                        <tr>
                            <th>Patient Name</th>
                            <th>Primary Language</th>
                            <th>Communication Method</th>
                            <th>Hearing Ability</th>
                            <th>Speech Clarity</th>
                            <th>Understanding Of Instrunction</th>
                            <th>Assistive Communication Devices</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of CommunicationData" >
                            <td> {{ data.patientName }} </td>
                            <td>{{ data.primaryLanguage }}</td>
                            <td>{{ data.communicationMethod }}</td>
                            <td>{{ data.haeringAbility }}</td>
                            <td>{{ data.speechClarity }}</td>
                            <td>{{ data.understandingOfInstrunction }}</td>
                            <td>{{ data.assistiveCommunicationDevices }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        
    </div>
    
</div>