
<div class="container-fluid ms-2">
            <h4>Current Providers</h4>
</div>

<form [formGroup]="thisForm" (ngSubmit)="addMyForm()" >
    
    <div class="container-fluid p-4 ">
        <div class="row p-4 border" >      
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Patient Name</label>
                <select formControlName="patientName" class="form-select" id="">
                    <option value="">Select</option>
                    <option *ngFor="let resi of residentData" [value]="resi.first_name">{{ resi.first_name }} {{ resi.last_name }}</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Provider Name</label>
                <input formControlName="providerName" type="text" class="form-control">
            </div>        
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Speciality</label>
                <input type="text" formControlName="speciality" class="form-control">
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Contact Information</label>
                <input type="text" formControlName="contactInfo" class="form-control">
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Address</label>
                <input type="text" formControlName="address" class="form-control">
            </div>  
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Date of Last Visit</label>
                <input type="date" formControlName="dateOfVisit" class="form-control">
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Notes on Provider</label>
                <textarea formControlName="notesOnProvider" class="form-control"></textarea>
            </div> 
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" class="btn btn-primary" >Submit</button>
            </div> 
        </div>
      
    </div>
    
</form>


<div class="container-fluid mt-5">
    
    <div class="row">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>Current Provider List</h4>
        </div>
        
    </div>
    
    <div class="row mt-4">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead style="background-color: #f6daf3; color: #3C3D37; white-space: nowrap;">
                        <tr>
                            <th>Patient Name</th>
                            <th>Provider Name</th>
                            <th>Speciality</th>
                            <th>Contact Info</th>
                            <th>Address</th>
                            <th>Date Of Visit</th>
                            <th>Notes On Provider</th>
                        </tr>
                    </thead>
                    <tbody style=" white-space: nowrap;">
                        <tr *ngFor="let data of cpData" >
                            <td> {{ data.patientName }} </td>
                            <td>{{ data.providerName }}</td>
                            <td>{{ data.speciality }}</td>
                            <td>{{ data.contactInfo }}</td>
                            <td>{{ data.address }}</td>
                            <td>{{ data.dateOfVisit }}</td>
                            <td>{{ data.notesOnProvider }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        
    </div>
    
</div>
