
<div class="container-fluid ms-2">
            <h4>Transportation  </h4>
</div>

<form [formGroup]="thisForm" (ngSubmit)="addMyForm()">
    
    <div class="container-fluid p-4">
        <div class="row p-4 border">         
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Patient Name</label>
                <select formControlName="patientName" class="form-select" id="">
                    <option value="">Select</option>
                    <option *ngFor="let resi of residentData" [value]="resi.first_name">{{ resi.first_name }} {{ resi.last_name }}</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Current Transportation Method</label>
                <select formControlName="currentTransportationMethod" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Personal Vehicle">Personal Vehicle</option>
                    <option value="Public Transport">  Public Transport</option>
                    <option value="Rideshare"> Rideshare </option>
                    <option value="Taxi"> Taxi</option>
                    <option value="Family/Friends">  Family/Friends</option>
                    <option value="None"> None</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Frequency of Transportation Use</label>
                <select formControlName="frequencyTransportationUse" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly"> Weekly</option>
                    <option value="Monthly">  Monthly</option>
                    <option value="Rarely"> Rarely</option>
                    <option value="Never"> Never</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Transportation Barriers</label>
                <textarea formControlName="TransportationBarries" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Emergency Contact for Transportation</label>
              <input type="text" formControlName="emergencyContactForTransportation" id="" class="form-control" >
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Distance Traveled</label>
                <select formControlName="distancetravelled" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Less than 5 miles">Less than 5 miles</option>
                    <option value="5-10 miles">   5-10 miles</option>
                    <option value="10-20 miles"> 10-20 miles</option>
                    <option value="More than 20 miles"> More than 20 miles</option>
                </select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                <label for="">Accessibility Needs</label>
                <div class="">
                    <div class="form-check ms-3">
                        <input class="form-check-input" type="radio" formControlName="acessibilityNeed" id="exampleRadios1" value="Wheelchair Accessible" checked>
                        <label class="form-check-label" for="exampleRadios1">
                            Wheelchair Accessible
                        </label>
                      </div>
                      <div class="form-check ms-3">
                        <input class="form-check-input" type="radio" formControlName="acessibilityNeed" id="exampleRadios2" value="Visual Assistance">
                        <label class="form-check-label" for="exampleRadios2">
                            Visual Assistance
                        </label>
                      </div>
                      <div class="form-check ms-3">
                        <input class="form-check-input" type="radio" formControlName="acessibilityNeed" id="exampleRadios3" value="Hearing Assistance">
                        <label class="form-check-label" for="exampleRadios3">
                            Hearing Assistance
                        </label>
                      </div>
                      <div class="form-check ms-3">
                        <input class="form-check-input" type="radio" formControlName="acessibilityNeed" id="exampleRadios3" value="Other">
                        <label class="form-check-label" for="exampleRadios3">
                            Other
                        </label>
                      </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>  
        </div>
        
     
    </div>
</form>



<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>Transportation List</h4>
        </div> 
    </div>
    <div class="row mt-4"> 
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">    
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead style="background-color: #f6daf3; color: #3C3D37;white-space: nowrap;">
                        <tr>
                            <th>Patient Name</th>
                            <th>Current Transportation Method</th>
                            <th>Frequency Transportation Use</th>
                            <th>Transportation Barries</th>
                            <th>Emergency Contact For Transportation</th>
                            <th>Distance Travelled</th>
                            <th>Acessibility Need</th>
                        </tr>
                    </thead>
                    <tbody style="white-space: nowrap;">
                        <tr *ngFor="let data of tranportationData" >
                            <td> {{ data.patientName }} </td>
                            <td>{{ data.currentTransportationMethod }}</td>
                            <td>{{ data.frequencyTransportationUse }}</td>
                            <td>{{ data.TransportationBarries }}</td>
                            <td>{{ data.emergencyContactForTransportation }}</td>
                            <td>{{ data.distancetravelled }}</td>
                            <td>{{ data.acessibilityNeed }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        
    </div>
    
</div>
