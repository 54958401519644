<div class="container-fluid ms-2">
            <h4>Safety</h4>
</div>

<form [formGroup]="thisForm" (ngSubmit)="addMyForm()">
    <div class="container-fluid p-4  ">
        <div class="row p-4 border ">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Patient Name</label>
                <select formControlName="patientName" class="form-select" id="">
                    <option value="">Select</option>
                    <option *ngFor="let resi of residentData" [value]="resi.first_name">{{ resi.first_name }} {{ resi.last_name }}</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Current Safety Concerns</label>
                <textarea formControlName="currentSafteyConcerns" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Home Safety Assessment</label>
                <select formControlName="homeSafteyAssessment" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Safe"> Safe</option>
                    <option value="Some Hazards Present"> Some Hazards Present</option>
                    <option value="Unsafe"> Unsafe </option>

                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Previous Accidents or Injuries</label>
                <textarea formControlName="previousAccidentsOrInjuries" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Emergency Contact Information</label>
                <input type="text" formControlName="emergencyContactInfo" id="" class="form-control">
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Safety Plan in Place</label>
                <select formControlName="safteyPlanInPlace" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Yes"> Yes</option>
                    <option value="No"> No</option>
                    <option value="In Developmemt"> In Developmemt </option>

                </select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                <label for="">Safety Equipment in Use</label>
                <div class="">
                    <div class="form-check ms-3">
                        <input class="form-check-input" type="radio" formControlName="safteyequipmentInUse"
                            id="exampleRadios1" value="None" checked>
                        <label class="form-check-label" for="exampleRadios1">
                            None
                        </label>
                    </div>
                    <div class="form-check  ms-3">
                        <input class="form-check-input" type="radio" formControlName="safteyequipmentInUse"
                            id="exampleRadios2" value="Alarm System">
                        <label class="form-check-label" for="exampleRadios2">
                            Alarm System
                        </label>
                    </div>
                    <div class="form-check  ms-3">
                        <input class="form-check-input" type="radio" formControlName="safteyequipmentInUse"
                            id="exampleRadios3" value="Grab Bars">
                        <label class="form-check-label" for="exampleRadios3">
                            Grab Bars
                        </label>
                    </div>
                    <div class="form-check  ms-3">
                        <input class="form-check-input" type="radio" formControlName="safteyequipmentInUse"
                            id="exampleRadios3" value="Emergency Button">
                        <label class="form-check-label" for="exampleRadios3">
                            Emergency Button
                        </label>
                    </div>
                    <div class="form-check  ms-3">
                        <input class="form-check-input" type="radio" formControlName="safteyequipmentInUse"
                            id="exampleRadios3" value="Smoke Detectors">
                        <label class="form-check-label" for="exampleRadios3">
                            Smoke Detectors
                        </label>
                    </div>
                    <div class="form-check  ms-3">
                        <input class="form-check-input" type="radio" formControlName="safteyequipmentInUse"
                            id="exampleRadios3" value="Other">
                        <label class="form-check-label" for="exampleRadios3">
                            Other </label>
                    </div>
                </div>
            </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" class="btn btn-primary">Submit</button>
            </div> </div>
      
</div>
</form>

<div class="container-fluid mt-5">
    
    <div class="row">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>Safety List</h4>
        </div>
        
    </div>
    
    <div class="row mt-4">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead style="background-color: #f6daf3; color: #3C3D37; white-space: nowrap;">
                        <tr>
                            <th>Patient Name</th>
                            <th>Current Saftey Concerns</th>
                            <th>Home Saftey Assessment</th>
                            <th>Previous Accidents Or Injuries</th>
                            <th>Emergency Contact Info</th>
                            <th>Saftey Plan In Place</th>
                            <th>Saftey equipment In Use</th>
                        </tr>
                    </thead>
                    <tbody style="white-space: nowrap;">
                        <tr *ngFor="let data of safetyData" >
                            <td> {{ data.patientName }} </td>
                            <td>{{ data.currentSafteyConcerns }}</td>
                            <td>{{ data.homeSafteyAssessment }}</td>
                            <td>{{ data.previousAccidentsOrInjuries }}</td>
                            <td>{{ data.emergencyContactInfo }}</td>
                            <td>{{ data.safteyPlanInPlace }}</td>
                            <td>{{ data.safteyequipmentInUse }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        
    </div>
    
</div>