
<div class="container-fluid ms-2">
            <h4>Current Medications</h4>
</div>

<form [formGroup]="thisForm" (ngSubmit)="addMyForm()" >
    
    <div class="container-fluid p-4">
        <div class="row p-4 border"> 
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Patient Name</label>
                <select formControlName="patientName" class="form-select" id="">
                    <option value="">Select</option>
                    <option *ngFor="let resi of residentData" [value]="resi.first_name">{{ resi.first_name }} {{ resi.last_name }}</option>
                </select>
            </div>  
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Medication Name</label>
                <input type="text"  class="form-control" formControlName="medicationName" >
            </div>     
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Dosage</label>
                <input type="text" class="form-control" formControlName="dosage" >
            </div>  
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Frequency of Administration</label>
                <select name="" class="form-select" formControlName="frequencyOfAdministration" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Once daily">Once daily</option>
                    <option value="Twice daily">  Twice daily</option>
                    <option value="Three times daily">  Three times daily </option>
                    <option value="As needed"> As needed</option>
                    <option value="Other"> Other</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Route of Administration</label>
                <select name="" class="form-select" formControlName="RouteOfAdministration" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Oral">Oral</option>
                    <option value="Injection"> Injection</option>
                    <option value="Topical">  Topical</option>
                    <option value="Inhalation"> Inhalation</option>
                    <option value="Other"> Other</option>
                </select>
            </div>  
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Start Date</label>
                <input type="date" class="form-control" formControlName="startDate" >
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Prescribing Healthcare Provider</label>
                <input type="text" class="form-control" formControlName="prescribingHealthCareProvider" >
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" class="btn btn-primary " >Submit</button>
            </div>  
        </div> 
       
    </div>
    
</form>


<div class="container-fluid mt-5">
    
    <div class="row">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>Current Medication List</h4>
        </div>
        
    </div>
    
    <div class="row mt-4">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead style="background-color: #f6daf3; color: #3C3D37;white-space: nowrap;">
                        <tr>
                            <th>Patient Name</th>
                            <th>Medication Name</th>
                            <th>dosage</th>
                            <th>Frequency Of Administration</th>
                            <th>Route Of Administration</th>
                            <th>Start Date</th>
                            <th>Prescribing Health Care Provider</th>
                        </tr>
                    </thead>
                    <tbody style="white-space: nowrap;">
                        <tr *ngFor="let data of cmData" >
                            <td> {{ data.patientName }} </td>
                            <td>{{ data.medicationName }}</td>
                            <td>{{ data.dosage }}</td>
                            <td>{{ data.frequencyOfAdministration }}</td>
                            <td>{{ data.RouteOfAdministration }}</td>
                            <td>{{ data.startDate }}</td>
                            <td>{{ data.prescribingHealthCareProvider }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        
    </div>
    
</div>