<div class="container-fluid ms-2">
            <h4>Community and Social Involvement</h4>
</div>

<form [formGroup]="thisForm" (ngSubmit)="addMyForm()">

    <div class="container-fluid p-4">
        <div class="row border p-4">
 
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Patient Name</label>
                <select formControlName="patientName" class="form-select" id="">
                    <option value="">Select</option>
                    <option *ngFor="let resi of residentData" [value]="resi.first_name">{{ resi.first_name }} {{ resi.last_name }}</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Social Activity Level</label>
                <select formControlName="socialActivityLevel" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Very Active">Very Active</option>
                    <option value="Moderately Active"> Moderately Active</option>
                    <option value="Occasionally Active"> Occasionally Active</option>
                    <option value="Not Active"> Not Active</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for=""> Preferred Social Activities</label>
                <textarea formControlName="perffredSocialActivities" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Frequency of Social Interaction</label>
                <select formControlName="frequencyOfSocialInteraction" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly"> Weekly</option>
                    <option value="Monthly"> Monthly</option>
                    <option value="Rarely"> Rarely</option>
                </select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                <label for="">Community Support Groups</label>
                <div class="">
                    <div class="form-check me-3">
                        <input class="form-check-input" type="radio" formControlName="communitySupportGroup" id="exampleRadios1"
                            value="Religious Groups" checked>
                        <label class="form-check-label" for="exampleRadios1">
                            Religious Groups
                        </label>
                    </div>
                    <div class="form-check me-3">
                        <input class="form-check-input" type="radio" formControlName="communitySupportGroup" id="exampleRadios2"
                            value="Social Clubs">
                        <label class="form-check-label" for="exampleRadios2">
                            Social Clubs
                        </label>
                    </div>
                    <div class="form-check me-3">
                        <input class="form-check-input" type="radio" formControlName="communitySupportGroup" id="exampleRadios3"
                            value="Volunteer Organizations">
                        <label class="form-check-label" for="exampleRadios3">
                            Volunteer Organizations
                        </label>
                    </div>
                    <div class="form-check me-3">
                        <input class="form-check-input" type="radio" formControlName="communitySupportGroup" id="exampleRadios3"
                            value="Therapy Groups">
                        <label class="form-check-label" for="exampleRadios3">
                            Therapy Groups
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Barriers to Social Involvement</label>
                <textarea formControlName="BarriesOfSocialInvolvement" id="" class="form-control"></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-4">
                <label for="">Community Event Participation</label>
                <select formControlName="communityEventParticipation" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Frequently">Frequently</option>
                    <option value="Occasionally"> Occasionally</option>
                    <option value="Rarely"> Rarely</option>
                    <option value="Never"> Never</option>
                </select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" class="btn btn-primary">Submit</button>
            </div></div>
   
      


    </div>

</form>


<div class="container-fluid mt-5">
    
    <div class="row">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>Community and Social Involvement List</h4>
        </div>
        
    </div>
    
    <div class="row mt-4">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead style="background-color: #f6daf3; color: #3C3D37; white-space: nowrap;">
                        <tr>
                            <th>Patient Name</th>
                            <th>Social Activity Level</th>
                            <th>perffredSocialActivities</th>
                            <th>Frequency Of Social Interaction</th>
                            <th>Community Support Group</th>
                            <th>Barries Of Social Involvement</th>
                            <th>Community Event Participation</th>
                        </tr>
                    </thead>
                    <tbody style="white-space: nowrap;">
                        <tr *ngFor="let data of CommunicationAndsocialData" >
                            <td> {{ data.patientName }} </td>
                            <td>{{ data.socialActivityLevel }}</td>
                            <td>{{ data.perffredSocialActivities }}</td>
                            <td>{{ data.frequencyOfSocialInteraction }}</td>
                            <td>{{ data.communitySupportGroup }}</td>
                            <td>{{ data.BarriesOfSocialInvolvement }}</td>
                            <td>{{ data.communityEventParticipation }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        
    </div>
    
</div>