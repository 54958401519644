
<div class="container-fluid ms-2">
            <h4>EPSDT (Early aur Periodic Screening, Diagnostic, and Treatment)</h4>
</div>

<form [formGroup]="thisForm" (ngSubmit)="addMyForm()">
    
    <div class="container-fluid p-4">
        <div class="row p-4 border">         
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Patient Name</label>
                <select formControlName="patientName" class="form-select" id="">
                    <option value="">Select</option>
                    <option *ngFor="let resi of residentData" [value]="resi.first_name">{{ resi.first_name }} {{ resi.last_name }}</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Date of Last EPSDT Screening</label>
              <input type="date" formControlName="dateOfLastEPSDTScreening" id="" class="form-control" >
            </div>    
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Screening Results</label>
              <textarea formControlName="screeningResult" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Follow-up Recommendations</label>
                <textarea formControlName="followUpRecommendations" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Current Health Assessments</label>
                <textarea formControlName="currentHealthAssessment" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Immunization Status</label>
                <textarea formControlName="immunizationStatus" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Care Coordination</label>
                <textarea formControlName="careCoordination" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>   
        </div>
       
    </div>
</form>


<div class="container-fluid mt-5">
    
    <div class="row">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>EPSDT List</h4>
        </div>
        
    </div>
    
    <div class="row mt-4">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead style="background-color: #f6daf3; color: #3C3D37; white-space: nowrap;">
                        <tr>
                            <th>Patient Name</th>
                            <th>Date Of Last EPSDT Screening</th>
                            <th>Screening Result</th>
                            <th>Follow Up Recommendations</th>
                            <th>Current Health Assessment</th>
                            <th>Immunization Status</th>
                            <th>Care Coordination</th>
                        </tr>
                    </thead>
                    <tbody style="white-space: nowrap;">
                        <tr *ngFor="let data of epsdtrData" >
                            <td> {{ data.patientName }} </td>
                            <td>{{ data.dateOfLastEPSDTScreening }}</td>
                            <td>{{ data.screeningResult }}</td>
                            <td>{{ data.followUpRecommendations }}</td>
                            <td>{{ data.currentHealthAssessment }}</td>
                            <td>{{ data.immunizationStatus }}</td>
                            <td>{{ data.careCoordination }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        
    </div>
    
</div>