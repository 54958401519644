<div class="container-fluid">
    <div class="row">
<div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">

    <div class="login-card">
        <div class="login-brand text-center mb-3">
            <img [src]="selectedLogo" class="img-fluid w-25" alt="RiverDoc Logo">
        </div>
    <h4 class="text-center">Login</h4>

    <form [formGroup]="loginForm">
        <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" id="email" formControlName="email" class="form-control"
                placeholder="Username" aria-label="Username" 
                [ngClass]="{'is-invalid': loginForm.controls['email'].invalid && ck }">
            <p class="text-danger" *ngIf="loginForm.controls['email'].invalid && ck"> Enter Your Email</p>
        </div>
    
        <div class="mb-3">
            <div class="d-flex justify-content-between">
                <label for="password" class="form-label">Password</label>
                <a  class="text-small" (click)="onForgotPasswordClick($event)">Forgot Password?</a>

            </div>
            <input type="password" id="password" formControlName="password" class="form-control"
                [type]="showPassword ? 'text' : 'password'" placeholder="Password" aria-label="Username"
                [ngClass]="{'is-invalid': loginForm.controls['password'].invalid && ck }">
            <p class="text-danger" *ngIf="loginForm.controls['password'].invalid && ck"> Enter Your Password</p>
        </div>
    
        <div class="mb-1 form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" (click)="togglePasswordVisibility()">
            <label class="form-check-label text-secondary fw-bold" for="exampleCheck1">Show Text</label>
        </div>
    
        <!-- Display API error message here -->
        <div *ngIf="apiErrorMessage" class="mb-3">
            <p class="text-danger">{{ apiErrorMessage }}</p>
        </div>
    
        <!-- <div class="mb-3">
            <button type="submit" class="btn btn-login" (click)="onChangess()">Master Login</button>
        </div> -->
    
        <div class="mb-3">
            <button type="submit" class="btn btn-login" (click)="login()">Login</button>
        </div>
    </form>
    
    

    <div class="simple-footer mt-5">
        <p>Copyright &copy; Mexdy Technology & IT Services 2024</p>
        <!-- <p>Design & Developed By <a href="https://www.infinitietech.com/" target="_blank" class="text-small">Infinitie
                Technologies</a></p> -->
    </div>
</div>
</div>
</div>
</div>