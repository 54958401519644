<div class="container-fluid ms-2">
            <h4>Self-care/Home Care</h4>
</div>

<form [formGroup]="thisForm" (ngSubmit)="addMyForm()">

    <div class="container-fluid p-4">
        <div class="row p-4  border">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Patient Name</label>
                <select formControlName="patientName" class="form-select" id="">
                    <option value="">Select</option>
                    <option *ngFor="let resi of residentData" [value]="resi.first_name">{{ resi.first_name }} {{ resi.last_name }}</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Daily Routine</label>
                <textarea formControlName="dailyRoutine" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Personal Hygiene</label>
                <select formControlName="personalHygiene" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Independent">Independent</option>
                    <option value="Requires Assistance"> Requires Assistance</option>
                    <option value="Fully Dependent"> Fully Dependent</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Mobility</label>
                <select formControlName="mobility" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Walks Independently">Walks Independently</option>
                    <option value="Uses Walker"> Uses Walker</option>
                    <option value="Wheelchair-bound"> Wheelchair-bound</option>
                    <option value="Bed-bound"> Bed-bound</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Medication Management</label>
                <select formControlName="medicationManagement" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Manages Independently">Manages Independently</option>
                    <option value="Needs Reminders"> Needs Reminders</option>
                    <option value="Requires Assistance"> Requires Assistance</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Diet and Nutrition</label>
                <textarea formControlName="dietAndNutrition" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                <label for="">Assistive Devices</label>
                <div class="">
                    <div class="form-check ms-3">
                        <input class="form-check-input" type="radio" formControlName="assistiveDevices" id="exampleRadios1"
                            value="Walker" checked>
                        <label class="form-check-label" for="exampleRadios1">
                            Walker
                        </label>
                    </div>
                    <div class="form-check ms-3">
                        <input class="form-check-input" type="radio" formControlName="assistiveDevices" id="exampleRadios2"
                            value="Wheelchair">
                        <label class="form-check-label" for="exampleRadios2">
                            Wheelchair
                        </label>
                    </div>
                    <div class="form-check ms-3">
                        <input class="form-check-input" type="radio" formControlName="assistiveDevices" id="exampleRadios3"
                            value="Hearing Aid">
                        <label class="form-check-label" for="exampleRadios3">
                            Hearing Aid
                        </label>
                    </div>
                    <div class="form-check ms-3">
                        <input class="form-check-input" type="radio" formControlName="assistiveDevices" id="exampleRadios3"
                            value="Glasses">
                        <label class="form-check-label" for="exampleRadios3">
                            Glasses
                        </label>
                    </div>
                    <div class="form-check ms-3">
                        <input class="form-check-input" type="radio" formControlName="assistiveDevices" id="exampleRadios3"
                            value="Other">
                        <label class="form-check-label" for="exampleRadios3">
                            Other
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Home Safety Concerns</label>
                <textarea formControlName="homeSafteyConcern" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Household Chores</label>
                <select formControlName="houseHoldChores" class="form-select" id="">
                    <option value="" selected hidden>Select</option>
                    <option value="Manages Independently">Manages Independently</option>
                    <option value="Needs Assistance"> Needs Assistance</option>
                    <option value="Fully Dependent"> Fully Dependent</option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Emergency Contacts</label>
                <input type="text" formControlName="emergencyContact" id="" class="form-control">
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-3">
                <label for="">Additional Notes</label>
                <textarea formControlName="additionalNotes" class="form-control" id=""></textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </div>
     


    </div>

</form>



<div class="container-fluid mt-5">
    
    <div class="row">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>Safety List</h4>
        </div>
        
    </div>
    
    <div class="row mt-4">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead style="background-color: #f6daf3; color: #3C3D37; white-space: nowrap;">
                        <tr>
                            <th>Patient Name</th>
                            <th>Daily Routine</th>
                            <th>Personal Hygiene</th>
                            <th>Mobility</th>
                            <th>Medication Management</th>
                            <th>Diet And Nutrition</th>
                            <th>Assistive Devices</th>
                            <th>Home Saftey Concern</th>
                            <th>House Hold Chores</th>
                            <th>Emergency Contact</th>
                            <th>Additional Notes</th>
                        </tr>
                    </thead>
                    <tbody style=" white-space: nowrap;">
                        <tr *ngFor="let data of salfHomeCareData" >
                            <td> {{ data.patientName }} </td>
                            <td>{{ data.dailyRoutine }}</td>
                            <td>{{ data.personalHygiene }}</td>
                            <td>{{ data.mobility }}</td>
                            <td>{{ data.medicationManagement }}</td>
                            <td>{{ data.dietAndNutrition }}</td>
                            <td>{{ data.assistiveDevices }}</td>
                            <td>{{ data.homeSafteyConcern }}</td>
                            <td>{{ data.houseHoldChores }}</td>
                            <td>{{ data.emergencyContact }}</td>
                            <td>{{ data.additionalNotes }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
        
    </div>
    
</div>